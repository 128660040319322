import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import { selectCertificates } from '../../redux/selectors/certificates';

export const ExportCSV = (props) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const certificates = useSelector(selectCertificates);
  const { fullList } = useSelector((state) => state);
  let arr = [];
  if (certificates.length) {
    certificates.forEach(({ application, assignDate, certificateNumber }) => {
      let region = '';
      let achievements = '';
      if (fullList && application.organisation) {
        fullList.forEach((el) => {
          el.forEach((element) => {
            if (element.id === application.organisation.regionId) {
              region = element.title;
            }
          });
        });
      }
      application.achievements.forEach((el) => {
        achievements += el.eventTitle + ' ' + el.eventResult + '; ';
      });
      let needRes = {
        Прізвище: application.athleteLastName,
        "Ім'я": application.athleteFirstName,
        'По батькові': application.athleteSecondName,
        'дата народження': application.birthday,
        'вид спору': application.sportTitle,
        'домашня адреса': region,
        Відомство: (application.organisation && application.organisation.shortName) || '',
        "Прізвище, ім'я, по батькові тренера, який підготував спортсмена":
          application.trainerLastNameFirst +
          ' ' +
          application.trainerFirstNameFirst[0] +
          '.' +
          application.trainerSecondNameFirst[0] +
          '.',
        'Дата присвоєння': assignDate,
        '№ наказу': '',
        '№ номер посвідчення': certificateNumber,
        'Підстава присвоєння (результат)': achievements,
      };
      arr.push(needRes);
    });
  }

  const exportToCSV = () => {
    props.uploadExel();
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, props.fileName + fileExtension);
  };

  const onClick = (e) => {
    e.stopPropagation();
    exportToCSV(arr, props.fileName);
  };

  return (
    <button className="icon-text-btn-secondary" onClick={onClick}>
      Завантажити Excel
    </button>
  );
};

// export const ExportCSVWord = (props) => {
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.txt';
//     const exportToCSV = () => {
//         props.uploadExel()
//         props.setSelectForm([])
//         props.setSelectId([])
//         const ws = XLSX.utils.json_to_sheet(props.csvData);
//         const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
//         const excelBuffer = XLSX.write(wb, { bookType: 'txt', type: 'array' });
//         const data = new Blob([excelBuffer], {type: fileType});
//         FileSaver.saveAs(data, props.fileName + fileExtension);
//     }
//     return (
//         <button className='icon-text-btn-secondary' style={{marginBottom:'25px'}} onClick={(e) => exportToCSV(props.csvData,props.fileName)}>Завантажити Word</button>
//     )
// }
