import {
  CERTIFICATEGETLIST_SUCCESS,
  CERTIFICATEGETITEM_SUCCESS,
  CERTIFICATEUNIQUEGETITEM_SUCCESS,
  CERTIFICATEGETREPLIST_SUCCESS
} from './types';
import CertificateService from '../services/certificate.service';

export const getCertificateList =
  (data, page = 1) =>
  (dispatch) => {
    return CertificateService.getCertificateList(data, page).then((response) => {
      dispatch({
        type: CERTIFICATEGETLIST_SUCCESS,
        list: response.items,
        total: response.total,
        offset: response.offset,
      });
      return response.items;
    });
  };

export const getAllCertificateList = (data) => (dispatch) => {
  return CertificateService.getAllCertificateList(data).then((response) => {
    dispatch({
      type: CERTIFICATEGETLIST_SUCCESS,
      list: response.items,
      total: response.total,
      offset: response.offset,
    });
    return response.items;
  });
};

export const getCertificateRepList = (data) => (dispatch) => {
  return CertificateService.getCertificateRepList(data).then((response) => {
    dispatch({
      type: CERTIFICATEGETREPLIST_SUCCESS,
      list: response.items,
      total: response.total,
      offset: response.offset,
    });
    return response.items;
  });
};


export const getMultipleCertificateList = (data) => (dispatch) => {
  return CertificateService.getMultipleCertificateList(data).then((response) => {
    dispatch({
      type: CERTIFICATEGETLIST_SUCCESS,
      list: response.items,
      total: response.total,
      offset: response.offset,
    });
    return response.items;
  });
};

export const getCertificateItem = (data) => (dispatch) => {
  return CertificateService.getCertificateItem(data).then((response) => {
    dispatch({
      type: CERTIFICATEGETITEM_SUCCESS,
      item: response,
    });
    return response;
  });
};

export const getCertificateUniqueList = (page) => (dispatch) => {
  return CertificateService.getUniqueAvailableOrderNumbers(page).then((response) => {
    dispatch({
      type: CERTIFICATEUNIQUEGETITEM_SUCCESS,
      list: response.items,
      total: response.total,
      offset: response.offset,
    });
    return response.items;
  });
};
