import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../../components/Avatar/Avatar';
import { useHistory } from 'react-router-dom';
import Form from '../DirectoriesPage/Form/Form';
import MatesService from '../../redux/services/mates.service';
import UsersDataService from '../../redux/services/user.service';
import { impersonate, logout } from '../../redux/actions/auth';
import { deleteUser } from '../../redux/actions/users';

const ProfilePage = (props) => {
  const history = useHistory();
  const [formActive, setFormActive] = useState(false);
  const [sport, setSport] = useState(false);
  const [form, setForm] = useState({});
  const { fullList } = useSelector((state) => state);
  const { currentOrganisation } = useSelector((state) => state.organisations);
  const [currentOrganisationSports, setCurrentOrganisationSports] = useState([]);
  const { isAdmin, isOwnProfile,isOwnProfileEdit, organisation, isHeadUser, item } = props;
  const [olimp, setOlimp] = useState(false);
  const [noolimp, setNoolimp] = useState(false);
  const [tryDelete, setTryDelete] = useState(false);

  const dispatch = useDispatch();

  const impersonateClick = (e) => {
    e.preventDefault();
    dispatch(impersonate(item.id))
      .then((res) => {
        if (typeof res === 'string') {
        } else {
          history.push('/profile');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteClick =(e)=>{
    e.preventDefault();
    setTryDelete(true);
  }
  const handleDeleteClick=(e)=>{
    e.preventDefault();
    setTryDelete(false);
    dispatch(deleteUser(item.id)).then((res) => {
      history.push('/applications');
    })
    .catch((e) => {
      console.log(e);
    });
  }

  const changeSport = () => {
    if (organisation && (organisation.type === 8 || organisation.type === 4 || (organisation.type === 6 && organisation.parentOrganisationId=='e6c8e5d9-e432-430a-9290-28317a1bdafc'))){
      setOlimp(false);
      setNoolimp(true);
    } else if (organisation && (organisation.type === 7 || organisation.type === 3)) {
      setNoolimp(false);
      setOlimp(true);
    }
    else if (organisation && (organisation.type === 6 && organisation.parentOrganisationId=='4182ffda-6187-4243-8555-12cd88bbc8cb')){
      setOlimp(true);
      setNoolimp(false);
      setCurrentOrganisationSports(currentOrganisation.sports);
    }

    const data = {
      sports: item.sports,
      mateId: item.id,
      id: item.organisation_id,
    };
    setForm(data);
    setSport(true);
    setFormActive(true);
  };

  const handleSportSave = useCallback(() => {
    MatesService.editMatesItem(form)
      .then((response) => {
        setOlimp(false);
        setNoolimp(false);
        setFormActive(false);
        props.loadUserProfile();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [form]);

  const backHandler = () => {
    history.goBack();
  };

  const setBoss = (e) => {
    let data = {
      value: e.target.checked,
      pos: 'isHeadEmployee',
      id: organisation.id,
      mateId: e.target.id,
    };
    MatesService.editMatesItem(data)
      .then((res) => {
        if(!isAdmin && isOwnProfileEdit){
          dispatch(logout());
          window.location.href = '/login'
        }else{
          props.loadUserProfile();
        }
      })
      .catch((e) => {});
  };

  const setNotifications = (e) => {
    UsersDataService.update(props.item.id, {
      notificationsEnabled: e.target.checked,
    })
      .then(() => {
        props.loadUserProfile();
      })
      .catch((e) => {});
  };

  const [error, setError] = useState([]);

  useEffect(() => {
    if (props.errMsg && props.errMsg.errors && props.errMsg.errors[0]) {
      setError(props.errMsg.errors[0]);
    } else {
      setError([]);
    }
  }, [props.errMsg]);

  return (
    <div className="main profile-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      <h1 className="public-header">Профіль користувача</h1>
      {!isOwnProfile && (
        <button className="icon-text-btn-secondary back" onClick={backHandler}>
          Назад
        </button>
      )}
       
      <div className="image-block">
        {item.picture ? <img id="output" src={item.picture} /> : <img id="output" />}
        {item.picture ? (
          <label htmlFor="image_uploads">Замінити фото</label>
        ) : (
          <label htmlFor="image_uploads">Завантажити фото</label>
        )}
        <input
          accept="image/*"
          type="file"
          id="image_uploads"
          name="picture"
          disabled={!isOwnProfile && !isAdmin}
          className="image-upload"
          onChange={props.loadFile}
        />
      </div>
      <form className="data-block">
        <h2>Особисті дані</h2>
        {!isAdmin && (
          <label className="labelText" htmlFor="">
            {' '}
            ПІБ співробітника
            <input
              type="text"
              disabled
              value={item ? item.lastName + ' ' + item.firstName + ' ' + item.secondName : ''}
            />
          </label>
        )}
        {
          <label className="labelText" htmlFor="">
            {' '}
            РНОКПП (Реєстраційний номер облікової картки платника податків)
            <input
              type="text"
              disabled
              value={
                item &&
                item.taxCode &&
                item.taxCode.length > 5 &&
                '...' + item.taxCode.substr(item.taxCode.length - 4)
              }
            />
          </label>
        }

        <label className="labelText" htmlFor="">
          {' '}
          УНЗР (Унікальний номер запису в Єдиному державному демографічному реєстрі)
          <input
            type="text"
            disabled
            value={
              item &&
              item.unzr &&
              item.unzr.length > 5 &&
              '...' + item.unzr.substr(item.unzr.length - 4)
            }
          />
        </label>

        <label className="labelText" htmlFor="">
          {' '}
          Посада
          {!organisation && <input type="text" disabled value="" />}
          {organisation && item.isHeadEmployee && <input type="text" disabled value="Керівник" />}
          {organisation && item.isHeadTrustedEmployee && (
            <input type="text" disabled value="Уповноважена особа" />
          )}
          {organisation && !item.isHeadEmployee && !item.isHeadTrustedEmployee && (
            <input type="text" disabled value="Cпівробітник" />
          )}
        </label>
        <label className="labelText" htmlFor="">
          {' '}
          Організація
          <input
            type="text"
            disabled
            value={
              organisation && organisation.type < 2
                ? organisation.name
                : organisation && organisation.type > 1
                ? 'Мінмолодьспорт'
                : ''
            }
          />
        </label>
        <label className="labelText" htmlFor="">
          {' '}
          Структурний підрозділ
          <span className="prompt">За наявності</span>
          <input
            type="text"
            disabled
            value={organisation && organisation.type > 1 ? organisation.name : ''}
          />
        </label>
        { (isAdmin || currentOrganisation )&& organisation && (
        // { (isAdmin || (isHeadUser && !isOwnProfileEdit ) )&& organisation && (
          <label className="form-switch labelText">
            <input id={item.id} type="checkbox" checked={item.isHeadEmployee} onChange={setBoss} />
            <i></i>Призначити керівником
          </label>
        )}
        {organisation && (
          <label className="form-switch labelText">
            <input
              id={item.id}
              type="checkbox"
              checked={item.notificationsEnabled}
              onChange={setNotifications}
            />
            <i style={{ position: 'absolute', marginTop: '4px' }}></i>
            <span style={{ display: 'inline-block', paddingLeft: '53px' }}>
              Отримувати сповіщення про надходження заявки на розгляд
            </span>
          </label>
        )}
        {isAdmin && (
          <button className="btn-primary text-btn-primary" onClick={impersonateClick}>
            Авторизуватися під цим користувачем
          </button>
        )}
        {isAdmin && (
          <button className="btn-primary text-btn-primary" onClick={deleteClick}>
           Видалити користувача
          </button>
        )}
        {organisation && (organisation.type === 7 || organisation.type === 8 || organisation.type === 3 || organisation.type === 4|| organisation.type === 6 ) && (
          <>
            <h2 style={{ all: 'initial', marginTop: '80px' }}>Курує види спорту</h2>
            {item &&
              item.sports &&
              item.sports.map((el) => {
                return (
                  <div className="sport-elements" key={el.id}>
                    {el.title}
                  </div>
                );
              })}
            <div className="button-control">
              <button type="button" className="btn-primary text-btn-primary" onClick={changeSport}>
                Редагувати{' '}
              </button>
            </div>
          </>
        )}
      </form>
      <form className="contact-block" onSubmit={props.saveChange}>
        <h2>Контакти</h2>
        {isAdmin && (
          <>
            <label className="labelText" htmlFor="">
              {' '}
              Прізвище співробітника
              <input
                type="text"
                value={item ? item.lastName : ''}
                name="lastName"
                disabled={!isOwnProfile && !isAdmin}
                onChange={props.handleChange}
              />
            </label>
            <label className="labelText" htmlFor="">
              {' '}
              Ім'я співробітника
              <input
                type="text"
                value={item ? item.firstName : ''}
                name="firstName"
                disabled={!isOwnProfile && !isAdmin}
                onChange={props.handleChange}
              />
            </label>
            <label className="labelText" htmlFor="">
              {' '}
              По батькові співробітника
              <input
                type="text"
                value={item ? item.secondName : ''}
                name="secondName"
                disabled={!isOwnProfile && !isAdmin}
                onChange={props.handleChange}
              />
            </label>
          </>
        )}
        <label className="labelText" htmlFor="">
          Робочий телефон
          <input
            type="text"
            onChange={props.handleChange}
            name="phone"
            disabled={!isOwnProfile && !isAdmin}
            value={item.phone ? item.phone : ''}
          />
        </label>
        <label className="labelText" htmlFor="">
          Електронна пошта
          <input
            type="email"
            onChange={props.handleChange}
            className={!props.validForm.email ? 'inputInvalid' : ''}
            name="email"
            disabled={!isOwnProfile && !isAdmin}
            value={item.email ? item.email : ''}
          />
          {error.param === 'email' && <span className="validError">{error.msg}</span>}
        </label>

        <label className="labelText" htmlFor="">
          Адреса
          <span className="prompt">де знаходиться робоче місце</span>
          <input
            type="text"
            onChange={props.handleChange}
            name="address"
            disabled={!isOwnProfile && !isAdmin}
            value={item.address ? item.address : ''}
          />
        </label>

        <label className="labelText" htmlFor="">
          Стать
          <select
              name="sex"
              className="profileSelect"
              disabled={!isOwnProfile && !isAdmin}
              onChange={props.handleChange}
            >
              <option value={null}></option>
              {fullList &&
                fullList[9] &&
                fullList[9].map((sex) => {
                    return (
                      <option
                        selected={item.sex === sex.id}
                        key={sex.id}
                        value={sex.id}
                        data-value={sex.id}
                        data-id={sex.id}
                        data-title={sex.title}
                      >
                        {sex.title}
                      </option>
                    );
                })}
            </select>
        </label>
        <div className="button-control">
          <button disabled={!props.changed} type="submit" className="btn-primary text-btn-primary">
            {' '}
            Зберегти зміни
          </button>
        </div>
      </form>
      {/* {isOwnProfile && (
        <form className="password-block" onSubmit={props.changePassword}>
          <h2>Зміна пароля</h2>
          <label className="labelText ">
            <span>Старий пароль</span>
            <span className="prompt">
              Мінімум 6 символів, 1 велика і 1 маленька букви та 1 цифра
            </span>
            <div className="btn">
              <input
                name="oldPassword"
                className={!props.validForm.oldPassword ? 'inputInvalid psw-input' : 'psw-input'}
                type={props.passwordShown ? 'text' : 'password'}
                value={props.password.oldPassword}
                onChange={props.changeHandler}
              />
              <i
                className={
                  !props.passwordShown ? 'icon password-icon' : 'icon password-icon-noActive'
                }
                onClick={props.togglePasswordVisibility}
              ></i>
            </div>
            {!props.validForm.oldPassword && <span className="validError">Перевірте пароль</span>}
            {error.param === 'oldPassword' && <span className="validError">{error.msg}</span>}
          </label>
          <label className="labelText ">
            <span>Новий пароль</span>
            <span className="prompt">
              Мінімум 6 символів, 1 велика і 1 маленька букви та 1 цифра
            </span>
            <div className="btn">
              <input
                name="newPassword"
                className={!props.validForm.newPassword ? 'inputInvalid psw-input' : 'psw-input'}
                type={props.passwordShown ? 'text' : 'password'}
                value={props.password.newPassword}
                onChange={props.changeHandler}
              />
              <i
                className={
                  !props.passwordShown ? 'icon password-icon' : 'icon password-icon-noActive'
                }
                onClick={props.togglePasswordVisibility}
              ></i>
            </div>
            {!props.validForm.newPassword && <span className="validError">Перевірте пароль</span>}
            {error.param === 'newPassword' && <span className="validError">{error.msg}</span>}
          </label>
          <label className="labelText ">
            <span>Повторити новий пароль</span>
            <span className="prompt">
              Мінімум 6 символів, 1 велика і 1 маленька букви та 1 цифра
            </span>
            <div className="btn">
              <input
                className={
                  !props.validForm.newPasswordRepeat ? 'inputInvalid psw-input' : 'psw-input'
                }
                type={props.passwordShown ? 'text' : 'password'}
                name="newPasswordRepeat"
                onChange={props.changeHandler}
                value={props.password.newPasswordRepeat}
              />
              <i
                className={
                  !props.passwordShown ? 'icon password-icon' : 'icon password-icon-noActive'
                }
                onClick={props.togglePasswordVisibility}
              ></i>
            </div>
            {!props.validForm.newPasswordRepeat && (
              <span className="validError">Перевірте пароль</span>
            )}
            {error.param === 'newPasswordRepeat' && <span className="validError">{error.msg}</span>}
          </label>
          <div className="button-control">
            <button
              type="submit"
              className="btn-primary text-btn-primary"
              disabled={
                (!(
                  !props.validForm.oldPassword ||
                  !props.validForm.newPassword ||
                  !props.validForm.newPasswordRepeat ||
                  error.param === 'newPassword' ||
                  error.param === 'oldPassword' ||
                  (props.password.newPassword && props.password.newPassword.length === 0) ||
                  (props.password.oldPassword && props.password.oldPassword.length === 0)
                ) &&
                  props.password.newPassword &&
                  props.password.oldPassword) ||
                props.password.newPassword === props.password.newPasswordRepeat
                  ? false
                  : true
              }
            >
              Змінити пароль
            </button>
          </div>
        </form>
      )} */}

      {tryDelete && 
        <div className="alert-label">
          <div>Ви впевнені, що бажаєте видалити даного користувача із системи?</div>
          <button className="icon-text-btn-secondary" onClick={handleDeleteClick}>
           <i className="declineTop-icon"></i>Видалити&nbsp;користувача
          </button>
          <button
              className="round-btn-secondary close"
              onClick={() =>setTryDelete(false)}
            ></button>
          </div>
      }

      {formActive && sport && (
        <Form
          sport={sport}
          currentOrganisationSports={currentOrganisationSports}
          olimp={olimp}
          noolimp={noolimp}
          setFormActive={setFormActive}
          saveAddChange={handleSportSave}
          form={form}
          setForm={setForm}
        />
      )}
    </div>
  );
};

export default ProfilePage;
