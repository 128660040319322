import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import Letter from './Letter';
import Comment from './Comment';
import Res from './Res';
import Ztu from './Ztu';
import Res1 from './Res1';
import Order from './Order';
import Order1 from './Order1';
import {
  getCertificateItem,
  getCertificateList,
  getMultipleCertificateList,
} from '../../redux/actions/certificate';
import { getApplicationItem } from '../../redux/actions/applications';
import { getDocumentsList } from '../../redux/actions/documents';
import { getApplicationsCommentList } from '../../redux/actions/applicationMessage';
import { getHeadEmployee } from '../../redux/actions/organisations';
import PodanNew from './PodanNew';
import PodanNew2 from './PodanNew2';
import { isUUID } from '../../utils';
import { saveAs } from "file-saver";
import { Packer } from "docx";
import { DocumentCreator } from './word/WordForDownload';
import { DocumentCreatorOrder } from './word/OrderForWork';

const PrintExample = () => {
  const [ready, setReady] = useState(false);
  const componentRef = useRef();
  const currentApplication = useSelector((state) => state.applications.currentApplication);
  const currentOrganisation = useSelector((state) => state.organisations.currentOrganisation);
  const documents = useSelector((state) => state.documents);
  const certificate = useSelector((state) => state.certificate);
  const dispatch = useDispatch();
  const fullList = useSelector((state) => state.fullList);
  const applicationMessage = useSelector((state) => state.applicationMessage);
  const history = useHistory();
  const [certArr, setCertArr] = useState([]);
  const params = useParams();
  const applicationId = useParams().id;
  const { currentHeadEmployee: headEmployee } = useSelector((state) => state.headEmployee);

  const isOrder = ['order', 'order1', 'order2', 'order3'].includes(params.type);
  const isSubmission = ['podan', 'podan2'].includes(params.type);
  const isRes = ['res', 'res1', 'ztu'].includes(params.type);

  const getHead = useCallback(
    (el) => {
      dispatch(getHeadEmployee(el)).then(() => {
        setReady(true);
      });
    },
    [ready, setReady],
  );

  const getList = useCallback(
    (orderNumber) => {
      dispatch(getCertificateList(orderNumber)).then(() => {
        setReady(true);
      });
    },
    [ready, setReady],
  );

  const getApplicationList = useCallback(
    (el) => {
      dispatch(getApplicationsCommentList({ id: applicationId })).then((res) => {
        setReady(true);
      });
    },
    [ready],
  );

  const getApplication = useCallback(async () => {
    await dispatch(getApplicationItem(applicationId)).then((response) => {
      setReady(true);
    });
  }, [applicationId]);

  const getDocuments = useCallback(async () => {
    await dispatch(getDocumentsList(applicationId)).then((response) => {
      setReady(true);
    });
  }, [ready]);

  const additional = () => {
    history.push(`/print/${applicationId}/order`);
  };
  const additional1 = () => {
    history.push(`/print/${applicationId}/order1`);
  };
  const additional2 = () => {
    history.push(`/print/${applicationId}/order2`);
  };
  const additional3 = () => {
    history.push(`/print/${applicationId}/order3`);
  };
  const firstTape = () => {
    history.push(`/print/${applicationId}/res`);
  };
  const secondTape = () => {
    history.push(`/print/${applicationId}/res1`);
  };
  const ztuTape = () => {
    history.push(`/print/${applicationId}/ztu`);
  };
  const podan1 = () => {
    history.push(`/print/${applicationId}/podan`);
  };
  const podan2 = () => {
    history.push(`/print/${applicationId}/podan2`);
  };

  const backClick = () => {
    if (currentApplication && currentApplication.id && isUUID(applicationId)) {
      history.push(`/applications/${currentApplication.id}`);
    } else if (isOrder || applicationId === 'staticlist') {
      history.push(`/applications`);
    } else {
      history.push(`/applications/${applicationId}`);
    }
  };

  if ((params.type === 'order' || params.type === 'order1') && !ready) {
    getList(applicationId);
  }

  const sportCategoryTitles = [];
  if (certificate && certificate.list && certificate.list.length > 0) {
    certificate.list.forEach((cert) => {
      if (sportCategoryTitles.indexOf(cert.application.sportCategoryTitle) === -1) {
        sportCategoryTitles.push(cert.application.sportCategoryTitle);
      }
    });
  }
  sportCategoryTitles.sort((a, b) => b.localeCompare(a, 'ru'));

  if ((params.type === 'res' || params.type === 'ztu') && !ready) {
    let arr = [];
    try {
      const storage = JSON.parse(sessionStorage.getItem('selectDep'));
      storage.forEach((elem) => {
        arr.push(elem.id);
      });
    } catch (e) {
      console.log(e);
    }

    let date = {
      applications: arr,
    };
    dispatch(getMultipleCertificateList(date)).then(() => {
      setCertArr(certificate.list);
      setReady(true);
    });
  }
  if (params.type === 'comment' && !ready) {
    getApplicationList();
  }
  if (params.type === 'podan' && !ready) {
    getApplication();
    getDocuments();
  }

  useEffect(() => {
    if (currentOrganisation && params.type === 'list' && !ready) {
      getApplication();
      getHead(currentOrganisation.id);
    }
  }, [currentOrganisation, params.type, ready]);

  if (
    document.getElementsByClassName('sidebar') &&
    document.getElementsByClassName('sidebar')[0] &&
    document.getElementsByClassName('sidebar')[0].hidden !== true
  ) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }

  const downloadClick =(e)=>{

    let documentCreator;
    if(params.type=='order'){
      documentCreator = new DocumentCreator();
    }else{
      documentCreator= new DocumentCreatorOrder();
    }

    console.log('params.type[5]')
    console.log(params.type[5])
    const doc = documentCreator.create([
      certificate,
      params.type[5],
      sportCategoryTitles,
      fullList
    ]);


    Packer.toBlob(doc).then(blob => {
      // console.log(blob);
      // saveAs(blob, "order.docx");
      // console.log("Document created successfully");
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = params.type=='order'?`Наказ.docx`:`Додаток.docx`;
      link.click();
    });
    
  }

  return (
    <div style={{ gridArea: 'article' }}>
      <button className="icon-text-btn-secondary back" onClick={backClick}>
        Назад
      </button>
      <ReactToPrint
        trigger={() => (
          <button style={{ margin: '30px 0 0 auto' }} className="icon-text-btn-primary btn-primary">
            Друкувати
          </button>
        )}
        content={() => componentRef.current}
      />
      {isOrder &&!!sportCategoryTitles.length &&  <button className="print icon-text-btn-secondary" style={{marginLeft:'20px'}} onClick={downloadClick}>
        Завантажити
      </button>}
      {isOrder && !!sportCategoryTitles.length && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={additional}
        >
          Наказ
        </button>
      )}
      {isOrder && !!sportCategoryTitles.length && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={additional1}
        >
          Додаток
        </button>
      )}
      {isOrder && sportCategoryTitles.length > 1 && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={additional2}
        >
          Додаток 2
        </button>
      )}
      {isOrder && sportCategoryTitles.length > 2 && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={additional3}
        >
          Додаток 3
        </button>
      )}
      {ready && isRes && certArr && certArr.length > 0 && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={firstTape}
        >
          Лицьова сторона
        </button>
      )}
      {ready && isRes && certArr && certArr.length > 0 && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={secondTape}
        >
          Тильна сторона
        </button>
      )}
      {ready && isRes && certArr && certArr.length > 0 && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={ztuTape}
        >
          ЗТУ/ЗМСУ
        </button>
      )}
      {ready && params.type === 'list' && (
        <Letter
          ref={componentRef}
          headEmployee={headEmployee}
          currentApplication={currentApplication}
          currentOrganisation={currentOrganisation}
        />
      )}
      {isSubmission && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={podan1}
        >
          Подання
        </button>
      )}
      {isSubmission && (
        <button
          className="icon-text-btn-secondary print"
          style={{ margin: '30px 0 0 20px' }}
          onClick={podan2}
        >
          Подання 2
        </button>
      )}
      {params.type === 'podan' && (
        <PodanNew
          ref={componentRef}
          currentApplication={currentApplication}
          currentOrganisation={currentOrganisation}
          documents={documents}
          fullList={fullList}
        />
      )}
      {params.type === 'podan2' && (
        <PodanNew2
          ref={componentRef}
          currentApplication={currentApplication}
          currentOrganisation={currentOrganisation}
          documents={documents}
          fullList={fullList}
        />
      )}
      {ready && params.type === 'comment' && (
        <Comment ref={componentRef} comment={applicationMessage} />
      )}
      {ready && params.type === 'res' && <Res ref={componentRef} certificate={certificate} />}
      {ready && params.type === 'res1' && <Res1 ref={componentRef} certificate={certificate} />}
      {ready && params.type === 'order' && (
        <Order ref={componentRef} fullList={fullList} certificate={certificate} />
      )}
      {ready && params.type === 'order1' && (
        <Order1
          ref={componentRef}
          fullList={fullList}
          sportCategoryTitles={sportCategoryTitles}
          type={params.type}
          index={1}
          certificate={certificate}
        />
      )}
      {ready && params.type === 'order2' && (
        <Order1
          ref={componentRef}
          fullList={fullList}
          sportCategoryTitles={sportCategoryTitles}
          type={params.type}
          index={2}
          certificate={certificate}
        />
      )}
      {ready && params.type === 'order3' && (
        <Order1
          ref={componentRef}
          fullList={fullList}
          sportCategoryTitles={sportCategoryTitles}
          type={params.type}
          index={3}
          certificate={certificate}
        />
      )}
      {ready && params.type === 'ztu' && (
        <Ztu
          ref={componentRef}
          fullList={fullList}
          newArr={sportCategoryTitles}
          type={params.type}
          certificate={certificate}
        />
      )}
    </div>
  );
};

export default PrintExample;
