import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';
import authService from './auth.service';

const getNotificationItem = (body) => {
  return axios
    .get(constants.API_URL + `/users/${body.id}/notifications/${body.itemId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const deleteNotificationItem = (body) => {
  return axios
    .delete(constants.API_URL + `/users/${body.id}/notifications/${body.itemId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getNotificationList = (data, pagination = 1) => {
  let filter = `?offset=${(pagination - 1) * 10}&limit=100&sort[sentAt]=desc`;

  return axios
    .get(constants.API_URL + `/users/${data.id}/notifications` + filter, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getNotificationListUnread = (data ) => {
  return axios
    .get(constants.API_URL + `/users/${data.id}/notifications/unread`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};



const setReadedNotification = async (body) => {
  return await axios
    .put(
      constants.API_URL + `/users/${body.id}/notifications/${body.itemId}`,
      {},
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  getNotificationItem,
  deleteNotificationItem,
  getNotificationList,
  setReadedNotification,
  getNotificationListUnread
};
