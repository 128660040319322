import React, { Suspense, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './App.css';
import { Sidebar } from './components/Sidebar/Sidebar';
import { Loader } from './components/Loader';
import { getUser } from './redux/actions/users';
import { fetchUserData, fetchUserOrganisation, logout } from './redux/actions/auth';
import { getOrganisationsItem } from './redux/actions/organisations';
import { getFullList } from './redux/actions/full-list';
import { Routes } from './routes';

import {
  selectCurrentOrganisationId,
  selectCurrentUserId,
  selectIsAdmin,
  selectIsLoggedIn,
} from './redux/selectors/auth';

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAdmin = useSelector(selectIsAdmin);
  const currentUserId = useSelector(selectCurrentUserId);
  const currentOrganisationId = useSelector(selectCurrentOrganisationId);

  const logOut = useCallback(() => {
    dispatch(logout());
    // history.push("/applications");
  }, [dispatch]);

  const getDictionaryList = useCallback(() => {
    const data = [
      'regions',
      'sport-categories',
      'sport-titles',
      'trainer-categories',
      'application-status-titles',
      'judge-categories',
      'tournament-ranks',
      'cover-letter-status-titles',
      'ministry-executives',
      'sex',
      'ticket-statuses',
      'category-catalog',
      'users-list-permissions',
      'departments'
    ];
    dispatch(getFullList(data));
  }, [dispatch]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(fetchUserData(currentUserId));
      dispatch(fetchUserOrganisation(currentOrganisationId));
      // TODO: remove it
      dispatch(getUser(currentUserId)).catch((e) => {
        if(!currentUserId){
          if(!isLoggedIn){
            logOut();
          }
        }
      });

      // TODO: remove it
      if (currentOrganisationId) {
        dispatch(getOrganisationsItem(currentOrganisationId));
      }
    } else {
      // logOut();
    }
    getDictionaryList();
  }, [currentUserId, currentOrganisationId, dispatch, getDictionaryList]);

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Sidebar />
        <Routes isLoggedIn={isLoggedIn} isAdmin={isAdmin} />
      </Suspense>
    </div>
  );
}

export default App;
