import {
  AlignmentType,
  Document,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  PageOrientation,
  TableCell,
  TableRow,
  Table,
  VerticalAlign,
  Header,
  PageNumber

} from "docx";

export class DocumentCreatorOrder {
  create([certificate, index, sportCategoryTitles, fullList]) {
    const receiptTabStops = [
      { type: TabStopType.LEFT, position: TabStopPosition.MAX - 800 },
    ];

    const columnWidth = TabStopPosition.MAX / 2;
    const receiptTabStops2 = [
      { type: TabStopType.CENTER, position: columnWidth+300 },
      { type: TabStopType.RIGHT, position: TabStopPosition.MAX+600 },
    ];

    const certificates = (certificate && certificate.list) || [];

    certificates.sort(function (a, b) {
      if (a.application.sportTitle > b.application.sportTitle) {
        return 1;
      }
      if (a.application.sportTitle < b.application.sportTitle) {
        return -1;
      }
      if (a.application.athleteLastName > b.application.athleteLastName) {
        return 1;
      }
      if (a.application.athleteLastName < b.application.athleteLastName) {
        return -1;
      }
      return 0;
    });

    let categoryTitle = sportCategoryTitles[index - 1];

    let categoryTitleGenitive = '';
    switch (categoryTitle) {
      case 'Неолімпійський':
        categoryTitleGenitive = 'неолімпійських видів спорту';
        break;
      case 'Олімпійський':
        categoryTitleGenitive = 'олімпійських видів спорту';
        break;
      default:
        categoryTitleGenitive = 'видів спорту осіб з інвалідністю';
    }

    const firstCertificate = certificates.length > 0 ? certificates[0] : null;

    let lastSportTitle = ""; // To track the previous sport title

    const document = new Document({
      sections: [
        {
          properties: {
            titlePage: true,
            page: {
              size: {
                orientation: PageOrientation.LANDSCAPE,
              },
              margin: {
                top:'30mm',
                left:'15mm',
                right:'10mm',
                bottom:'30mm',
            },
            },
          },
          headers: {
            default: new Header({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                children: [PageNumber.CURRENT],
                                size: 28,
                            }),
                        ],
                    }),
                    new Paragraph({
                      alignment: AlignmentType.RIGHT,
                      children: [
                          new TextRun({
                              children: [`Продовження додатка ${sportCategoryTitles.length>1?index:''}`],
                              size: 28,
                          }),
                      ],
                  }),
                ],
            }),
            first: new Header({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.RIGHT,
                        children: [
                            
                            new TextRun({
                                children: [''],
                            }),
                        ],
                    }),
                ],
            }),
        },
          children: [
            // Header Information
            new Paragraph({
              tabStops: receiptTabStops,
              alignment: AlignmentType.LEFT,
              spacing: {
                after: 140,
              },
              children: [
                new TextRun({
                  text: `\tДодаток ${sportCategoryTitles.length>1?index:''}`,
                  size: 28,
                }),
              ],
            }),
            new Paragraph({
              tabStops: receiptTabStops,
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: `\tдо наказу Міністерства молоді та спорту України`,
                  size: 28,
                }),
              ],
            }),
            new Paragraph({
              tabStops: receiptTabStops,
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: `\t"Про присвоєння спортивного звання`,
                  size: 28,
                }),
              ],
            }),
            new Paragraph({
              tabStops: receiptTabStops,
              alignment: AlignmentType.LEFT,
              spacing: {
                after: 280,
              },
              children: [
                new TextRun({
                  text: `\t"${firstCertificate ? firstCertificate.application.athleteCategoryTitle.split('/')[0] : 'Спортивне звання'}"`,
                  size: 28,
                }),
              ],
            }),
            // Title Information
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `Список`,
                  size: 28,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `спортсменів, яким присвоєно спортивне звання "${firstCertificate?.application.athleteCategoryTitle.split('/')[0]}"`,
                  size: 28,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 280,
              },
              children: [
                new TextRun({
                  text: `з ${categoryTitleGenitive}`,
                  size: 28,
                  bold: true,
                }),
              ],
            }),
            // Table Header Row
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: `№ заявки`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          children: [
                            new TextRun({
                              text: `Прізвище, ім'я, по батькові`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: `Область, місто`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: `Відомство`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          children: [
                            new TextRun({
                              text: `ПІБ тренера, який підготував спортсмена`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          children: [
                            new TextRun({
                              text: `Підстава присвоєння (результат)`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: ``,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          children: [
                            new TextRun({
                              text: `1`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: `2`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: `3`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          children: [
                            new TextRun({
                              text: `4`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            after: 100,
                            before: 100,
                          },
                          indent: {
                            start:200,
                            end:200
                          },
                          children: [
                            new TextRun({
                              text: `5`,
                              size: 28,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                // Iterate over filtered certificates and insert sport title rows
                ...certificates
                  .filter((certificate) => {
                    return (
                      certificate.application.sportCategoryTitle === categoryTitle ||
                      (certificate.application.sportCategoryTitle.includes("Види спорту осіб з інвалідністю") &&
                        categoryTitle.includes("Види спорту осіб з інвалідністю"))
                    );
                  })
                  .map((certificate, ind) => {
                    let region, company, achievements = '';

                    // Lookup region and company
                    if (fullList) {
                      fullList.forEach((el) => {
                        el.forEach((element) => {
                          if (element.id === certificate.application.regionId) {
                            region = element.title;
                          } else if (element.id === certificate.application.company) {
                            company = element.title;
                          }
                        });
                      });
                    }

                    // Collect achievements
                    certificate.application.achievements.forEach((el) => {
                      achievements += `${el.eventTitle} ${el.eventResult}\n`;
                    });

                    const sportTitleRow = [];

                    // Check if the sport title has changed
                    if (certificate.application.sportTitle !== lastSportTitle) {
                      lastSportTitle = certificate.application.sportTitle;

                      // Insert sport title row
                      sportTitleRow.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              verticalAlign: VerticalAlign.CENTER,
                              children: [
                                new Paragraph({
                                  spacing: {
                                    after: 100,
                                    before: 100,
                                  },
                                  indent: {
                                    start:200,
                                    end:200
                                  },
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                    new TextRun({
                                      text: `${certificate.application.sportTitle}`,
                                      size: 28,
                                      bold: true,
                                    }),
                                  ],
                                }),
                              ],
                              columnSpan: 6,
                            }),
                          ],
                        })
                      );
                    }

                    // Render row for each filtered certificate
                    const certificateRow = new TableRow({
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              spacing: {
                                after: 100,
                                before: 100,
                              },
                              indent: {
                                start:200,
                                end:200
                              },
                              alignment: AlignmentType.LEFT,
                              children: [new TextRun({ text: `${certificate.application?.officeCode?.slice(0, 5)}`, size: 28 })],
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              spacing: {
                                before: 100,
                              },
                              indent: {
                                start:200,
                                end:200
                              },
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${certificate.application.athleteLastName.toUpperCase()}`,
                                  size: 28,
                                }),
                              ],
                            }),
                            new Paragraph({
                              spacing: {
                                after: 100,
                              },
                              indent: {
                                start:200,
                                end:200
                              },
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text:`${certificate.application.athleteFirstName.toLowerCase().replace(/^\p{L}/u, (c) => c.toUpperCase())} ${certificate.application.athleteSecondName.toLowerCase().replace(/^\p{L}/u, (c) => c.toUpperCase())}`,
                                  size: 28,
                                })
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              spacing: {
                                after: 100,
                                before: 100,
                              },
                              indent: {
                                start:200,
                                end:200
                              },
                              alignment: AlignmentType.LEFT,
                              children: [new TextRun({ text: `${region}`, size: 28 })],
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              spacing: {
                                after: 100,
                                before: 100,
                              },
                              indent: {
                                start:200,
                                end:200
                              },
                              alignment: AlignmentType.LEFT,
                              children: [new TextRun({ text: `${company || ''}`, size: 28 })],
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              spacing: {
                                after: 100,
                                before: 100,
                              },
                              indent: {
                                start:200,
                                end:200
                              },
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${certificate.application.trainerLastNameFirst.toLowerCase().replace(/^\p{L}/u, (c) => c.toUpperCase())} ${certificate.application.trainerFirstNameFirst[0]}.${certificate.application.trainerSecondNameFirst[0]}.`,
                                  size: 28,
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              spacing: {
                                after: 100,
                                before: 100,
                              },
                              indent: {
                                start:200,
                                end:200
                              },
                              alignment: AlignmentType.LEFT,
                              children: [new TextRun({ text: `${achievements}`, size: 28 })],
                            }),
                          ],
                        }),
                      ],
                    });

                    // Combine the sport title row and the certificate row
                    return [...sportTitleRow, certificateRow];
                  })
                  .flat(), // Flatten array of rows
              ],
            }),
          ],
        },
      ],
    });

    return document;
  }
}
