import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '../../../components/Avatar/Avatar';
import Pagination from '../../../components/Pagination/Pagination';
import { getCertificateList } from '../../../redux/actions/certificate';
import Form from '../../DirectoriesPage/Form/Form';
import { PAGE_SIZE_CERTIFICATES } from '../../../constants/const';
import RosterService from '../../../redux/services/roster.service';
import { selectCurrentOrganisation, selectIsAdmin } from '../../../redux/selectors/auth';
import { selectRegions } from '../../../redux/selectors/dictionaries';
import CertificateService from '../../../redux/services/certificate.service';
import setSex from '../../../helpers/setSex';
import NewLoader from '../../../components/NewLoader';



const AuthRosterPage = () => {
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = false;
  }
  const [currentPage, setCurrentPage] = useState(1);
  const { certificate, list,fullList } = useSelector((state) => state);
  const dispatch = useDispatch();
  const currentOrganisation = useSelector(selectCurrentOrganisation);

  const [selected, setSelected] = useState('assignDate');
  const [prevSelected, setPrevSelected] = useState('assignDate');
  const [currentSort, setCurrentSort] = useState('desc');
  const [searchValue, setSearchValue] = useState(localStorage.getItem('certificatesSearch') || '');
  const [search, setSearch] = useState(localStorage.getItem('certificatesSearch') || '');
  const [filterForm, setFilterForm] = useState([]);
  const [filter, setFilter] = useState(false);
  const [formActive, setFormActive] = useState(false);
  const [modal, setModal] = useState(false);
  const isAdmin = useSelector(selectIsAdmin);
  const [downloadForm, setDownloadForm] = useState({});
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [ready, setReady] = useState(false);
  const [intervalId, setIntervalId] = useState(false);
  const [subtab, setSubTab] = useState({
    all: true,
    clone: false,
    refused: false,
  });

  const regions = useSelector(selectRegions);
  const history = useHistory();
  const reviewClick = () => {
    history.push('/report');
  };
  const getCertificates = useCallback(
    async (arr, page) => {
      let data = [];
      setReady(false);

      if (search.length > 2 && (!arr || arr[0])) {
        data.push({ name: 'all', value: search });
      }

      if (sessionStorage.cert_all) {
        let all = JSON.parse(sessionStorage.cert_all);
        setFilterForm(all);
        if (all.length > 0) {
          all.forEach((element) => {
            if (element && element.value.length > 0) {
              data.push(element);
            }
          });
        }
      }

      if (
        arr &&
        arr[0] &&
        arr[0].sortName &&
        arr[0].sortName.length > 0 &&
        arr[0].sortName === selected
      ) {
        data.push({ sortName: selected, sortValue: arr[0].sortValue });
      } else if (
        arr &&
        arr[0] &&
        arr[0].sortName &&
        arr[0].sortName.length > 0 &&
        arr[0].sortName !== selected
      ) {
        data.push({ sortName: arr[0].sortName, sortValue: arr[0].sortValue });
      } else if (arr && arr.name && arr.name.length > 0) {
        data.push({ sortName: selected, sortValue: currentSort });
        data.push(arr);
      } else {
        data.push({ sortName: selected, sortValue: currentSort });
      }

      if (subtab.clone){
        data.push({ name: 'hasCopy', value: '1' });
      }else if (subtab.refused){
        data.push({ name: 'isDecline', value: '1' });
      }else{
        data.push({ name: 'isDecline', value: '0' });
      }


      await dispatch(getCertificateList(data, page)).then((res) => {
        setReady(true);
      });
    },
    [currentPage, selected, currentSort, search,subtab, ready],
  );

  const rosterDownloadClick = (e) => {
    setModal(true);
  };

  const getNosology = (nosologyId) => {
    const nosology = fullList[1].filter((item) => item.id === nosologyId).pop();
    return nosology ? nosology.shortTitle : '';
  };

  const saveRosterDownload = () => {
    let data=subtab;
    console.log(downloadForm);
    data.urlDecline=downloadForm.urlDecline;
    RosterService.startRosterFile(data)
      .then(() => {
        setReady(false);
        setModal(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const searchHandler = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      localStorage.setItem('certificatesSearch', value);
      if (value.length > 2) {
        setSearch(value);
        let data = { name: 'all', value: e.target.value };
        getCertificates(data,1);
      } else {
        setSearch('');
        getCertificates({},1);
      }
    }, 1000)
    setIntervalId(newIntervalId);  
  };

  const clickEvent =(event)=>{
    if(isAdmin || currentOrganisation.type=='10'){
      if (event.target.nodeName !== 'INPUT') {
        history.push(`/applications/${event.currentTarget.id}`);
      }

    }
  }
  const clickSubHandler = (event) => {
    if(ready){
      setCurrentPage(1);
      let data={  
        all: false,
        clone: false,
        refused: false,}
        data[event.target.accessKey]=true;
      setSubTab(data);
      setReady(false);
    }
  };

  const deleteRosterClick = (event) => {
    let id = event.currentTarget.id;
    setDeleteId(id);
    setDeleteAlert(true);
  };

  const handleDelete = () => {
    CertificateService.deleteCertificate(deleteId).then(() => {
      getCertificates();
    });
    setDeleteId('');
    setDeleteAlert(false);
  };

  const onSortChange = (event) => {
    setCurrentPage(1);
    let data = [];
    let sort = 'asc';
    let field = event.target.id;
    setSelected(field);

    if (field !== prevSelected) {
      setPrevSelected(field);
      setCurrentSort('asc');
    } else if (selected === prevSelected) {
      if (currentSort === 'asc') {
        setCurrentSort('desc');
        sort = 'desc';
      } else if (currentSort === 'desc') {
        setCurrentSort('asc');
        sort = 'asc';
      }
    }
    data.push({ sortName: field, sortValue: sort });
    void getCertificates(data);
  };

  const filterClick = () => {
    setFilter(true);
    setFormActive(true);
  };

  const filterSave = () => {
    setFormActive(false);
    setFilter(false);
    sessionStorage.setItem('cert_all', JSON.stringify(filterForm));
    void getCertificates();
  };

  const getRegionTitle = (regionId) => {
    const region = regions.filter((item) => item.id === regionId).pop();
    return region ? region.title : '';
  };

  useEffect(() => {
    if(!ready){
      getCertificates();
    }
  }, [ready]);

  return (
    <div className="main auth-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <button
          className={
            filterForm && filterForm.length > 0
              ? 'icon-text-btn-secondary filter filter-on'
              : 'icon-text-btn-secondary filter'
          }
          style={{ marginTop: '30px' }}
          onClick={filterClick}
        >
          Фільтр
        </button>
        <button className="icon-text-btn-secondary docs" onClick={reviewClick}>
          Звіти
        </button>
        {/* <button className="icon-text-btn-secondary filter">Фільтр</button> */}
        {isAdmin && (
          <button className="icon-text-btn-secondary filter" onClick={rosterDownloadClick}>
            Завантажити Реєстр
          </button>
        )}
        <Avatar />
      </div>

      <h1 className="public-header">Реєстр присвоєних спортивних звань  {certificate?.total && '(' + certificate?.total +')'}</h1>
      <label className="search">
        <div className="btn">
          <input
            className="search-input"
            type="text"
            onChange={searchHandler}
            value={searchValue}
            placeholder="Пошук (мінімум 3 символа)"
          />
          <i className="icon search-icon"></i>
        </div>
      </label>

      <div className="auth-subtab">
          <button
            accessKey="all"
            className={subtab.all ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Присвоєні
          </button>
          <button
            accessKey="refused"
            className={subtab.refused ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Скасовані
          </button>
          {(isAdmin|| currentOrganisation?.type=='10') &&<button
            accessKey="clone"
            className={subtab.clone ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Дублікати посвідчення
          </button>}
          
        </div>
      <div className="public-table" style={{ overflowX: 'auto' }}>
      {ready ?
        <table className="public-table-cust roster">
          <thead>
            <tr className="roster">
              {/* <th className="selected"> <span>№ п/п</span></th> */}
              <th
                onClick={onSortChange}
                className={selected === 'assignDate' ? `selected ${currentSort}` : ''}
                id="assignDate"
              >
                <span id="assignDate">Присвоєння</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'athleteName' ? `selected ${currentSort}` : ''}
                id="athleteName"
              >
                <span id="athleteName">ПІБ спортсмена/тренера</span>
              </th>
              <th
               id="sex"
              >
                Стать
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'athleteCategoryTitle' ? `selected ${currentSort}` : ''}
                id="athleteCategoryTitle"
              >
                <span id="athleteCategoryTitle">Звання</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'sportTitle' ? `selected ${currentSort}` : ''}
                id="sportTitle"
              >
                <span id="sportTitle">Вид спорту</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'organisationName' ? `selected ${currentSort}` : ''}
                id="organisationName"
              >
                <span id="organisationName">Суб’єкт подання</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'company' ? `selected ${currentSort}` : ''}
                id="company"
              >
                <span id="company">Відомство</span>
              </th>
              <th>Регіон</th>
              <th
                onClick={onSortChange}
                className={selected === 'trainerName' ? `selected ${currentSort}` : ''}
                id="trainerName"
              >
                <span id="trainerName">Тренер</span>
              </th>
              <th
                className={'trainerSex'}
                id="trainerSex"
              >
                <span id="trainerSex">Стать тренера</span>
              </th>

              <th
                onClick={onSortChange}
                className={selected === 'orderNumber' ? `selected ${currentSort}` : ''}
                id="orderNumber"
              >
                <span id="orderNumber">Наказ</span>
              </th>
              {(isAdmin|| currentOrganisation?.type=='10')  && <th
                onClick={onSortChange}
                className={selected === 'certificateNumber' ? `selected ${currentSort}` : ''}
                id="certificateNumber"
              >
                <span id="certificateNumber">Посвідчення</span>
              </th>}
              <th>
                Видано
              </th>
              <th>Підстава присвоєння</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {certificate &&
              certificate.list &&
              certificate.list.length > 0 &&
              certificate.list.map((el, index) => {
                if (el.assignDate) {
                  return (
                    <tr className="roster" key={el.id} >
                      {/* <td>1</td> */}
                      <td>
                        {new Date(el.assignDate).toLocaleString('uk-UA', {
                          day: 'numeric',
                          month: 'numeric',
                          year: 'numeric',
                        })}
                      </td>
                      <td id={el.application.id} onClick={clickEvent} style={{cursor:'pointer'}}>
                        {el.application.athleteLastName} {el.application.athleteFirstName}{' '}
                        {el.application.athleteSecondName} 
                      </td>
                      <td>
                        {el.application.sex?list[el.application.sex]:'---'}
                      </td>
                      <td>
                        {el.application.athleteCategoryTitle &&
                        el.application.athleteCategoryTitle.split('/') &&
                        el.application.athleteCategoryTitle.split('/')[0]
                          ? el.application.athleteCategoryTitle.split('/')[0]
                          : el.application.athleteCategoryTitle}

                          <br />
                          <span style={{color:'grey'}}>
                            {el.application.sportCategoryId!=='f925fa34-258b-4d60-a604-eca9b027596f' &&  el.application.sportCategoryId!== '37a14ca2-4b35-4d3c-a180-4340338ab4eb' &&  el.application.sportCategoryId!=='1a5066b7-3dea-4fa1-8c16-dd5a449cb317' && fullList?.length>0 && getNosology(el.application.sportCategoryId)} 
                          </span>
                      </td>
                      <td>{el.application.sportTitle}</td>
                      <td>
                        {el.application.organisation && el.application.organisation.shortName
                          ? el.application.organisation.shortName
                          : '----'}
                      </td>
                      <td>
                        {el.application.company && el.application.company!='null'? list[el.application.company] || el.application.company: '----'}
                      </td>
                      <td>
                      {(el.application.organisation && el.application.organisation.regionId) || el.application.regionId
                          ? getRegionTitle(el.application?.organisation?.regionId || el.application.regionId)
                          : '----'}
                      </td>
                      <td>
                        {el.application.trainerLastNameFirst
                          ? el.application.trainerLastNameFirst.toLowerCase().replace(/^\p{L}/u, (c) => c.toUpperCase())                         +
                            ' ' +
                            el.application.trainerFirstNameFirst +
                            ' ' +
                            el.application.trainerSecondNameFirst
                          : ''}
                        {el.application.trainerLastNameSecond
                          ? ', ' +
                            el.application.trainerLastNameSecond +
                            ' ' +
                            el.application.trainerFirstNameSecond +
                            ' ' +
                            el.application.trainerSecondNameSecond 
                          : ''}
                        {el.application.trainerLastNameThird
                          ? ', ' +
                            el.application.trainerLastNameThird +
                            ' ' +
                            el.application.trainerFirstNameThird +
                            ' ' +
                            el.application.trainerSecondNameThird 
                          : ''}
                          {el.application.isFirstTrainer && "Перший тренер"}
                      </td>
                      <td>
                        {el.application.trainerSexFirst ? list[el.application.trainerSexFirst] : '----'} 
                        {el.application.trainerSexSecond && ', '+list[el.application.trainerSexSecond]} 
                        {el.application.trainerSexThird && ', '+list[el.application.trainerSexThird]} 
                      </td>
                      <td>{el.orderNumber}</td>
                      {(isAdmin|| currentOrganisation?.type=='10') &&<td>{el.certificateNumber}</td>}
                      <td>
                      {el.recipientName}
                      </td>
                      <td>
                        {el &&
                          el.application.achievements &&
                          el.application.achievements.length > 0 &&
                          el.application.achievements.map((elem, index) => {
                            return (
                              <React.Fragment key={elem.eventTitle + index}>
                                {' '}
                                {elem.eventTitle} {elem.eventResult} <br />{' '}
                              </React.Fragment>
                            );
                          })}
                      </td>
                      <td>
                        {el.url?.length>0&&
                         <button
                         className="icon-text-btn-secondary publicIcon"
                         onClick={() =>
                           window.open(el.url)
                         }
                          >
                            Копія&nbsp;наказу
                          </button>
                        }
                        {el && el.application.tournamentConfirmationDocumentUrl && (
                          <button
                            className="icon-text-btn-secondary publicIcon"
                            onClick={() =>
                              window.open(el.application.tournamentConfirmationDocumentUrl)
                            }
                          >
                            Протокол&nbsp;змагань
                          </button>
                        )}
                      </td>
                      <td>
                        {isAdmin && (
                          <button
                            className="icon-text-btn-secondary publicIcon"
                            id={el.application.id}
                            onClick={deleteRosterClick}
                          >
                            Видалити
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
          {certificate && certificate.total > PAGE_SIZE_CERTIFICATES && (
            <tfoot className="">
              <tr>
                <td colSpan="8">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={certificate.total}
                    pageSize={PAGE_SIZE_CERTIFICATES}
                    onPageChange={async (page) => {
                      await setCurrentPage(page);
                      await getCertificates(null, page);
                    }}
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
       :
       <>
       <NewLoader/>
       </>
       }
      </div>
      {formActive && filter && (
        <Form
          form={filterForm}
          saveAddChange={filterSave}
          filter={filter}
          filterCert={true}
          setForm={setFilterForm}
          setFormActive={setFormActive}
        />
      )}
      {modal && (
        <Form
          form={downloadForm}
          setForm={setDownloadForm}
          saveAddChange={saveRosterDownload}
          setFormActive={setModal}
          subtab={subtab}
          rosterDownload={true}
        />
      )}

      {deleteAlert && (
        <>
          <div className="alert-label">
            <div>Ви впевнені, що хочете видалити?</div>
            <div className="alert-label-buttons">
              <button className="icon-text-btn-secondary garbage" onClick={handleDelete}>
                Видалити
              </button>
              <button
                className="round-btn-secondary close"
                onClick={() => {
                  setDeleteAlert(false);
                  setDeleteId('');
                }}
              ></button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AuthRosterPage;
