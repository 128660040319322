import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import LoginPageAdmin from './pages/LoginPage/LoginPageAdmin';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';
import PrintExample from './pages/PrintPages/PrintExample';
import Page404 from './pages/Page404/Page404';
import ApplicationsPage from './pages/ApplicationsPage/ApplicationsPage';
import RosterPage from './pages/RosterPage/RosterPage';
import ProfilePageController from './pages/ProfilePage/ProfilePageController';
import RestorePage from './pages/RestorePage/RestorePage';
import DirectoriesPage from './pages/DirectoriesPage/DirectoriesPage';
import OrganisationsPage from './pages/OrganisationsPage/OrganisationsPage';
import MateItemPage from './pages/MateItemPage/MateItemPage';
import AddMateItemPage from './pages/AddMateItemPage/AddMateItemPage';
import CreateApplicationPage from './pages/CreateApplicationPage/CreateApplicationPage';
import MessagePage from './pages/MessagePage/MessagePage';
import NewList from './pages/CreateApplicationPage/ApplyLists/NewList/NewList';
import AddTrainer from './pages/AddTrainer/AddTrainer';
import ReportPage from './pages/ReportPage/ReportPage';
import InstructionsPage from './pages/InstructionsPage/InstructionsPage';
import TestPage from './pages/TestPage/TestPage';
import SupportListPage from './pages/SupportPages/SupportListPage/SupportListPage';
import SupportItemPage from './pages/SupportPages/SupportItemPage/SupportItem';
import OrgPage from './pages/OrgPage/OrgPage';
import MateItemsListPage from './pages/MateItemsListPage/MateItemsListPage';

export const Routes = ({ isAdmin, isLoggedIn }) => {
  return (
    <Switch>
      <Route path="/login" exact render={() => <LoginPage />} />
      <Route path="/loginAdmin" exact render={() => <LoginPageAdmin />} />
      {!isLoggedIn && <Route path="/registration" exact render={() => <RegistrationPage />} />}
      {!isLoggedIn && <Route path="/restore" exact render={() => <RestorePage />} />}
      <Route
        path="/applications"
        exact
        render={() => <ApplicationsPage isLoggedIn={isLoggedIn} />}
      />
      {!isLoggedIn && <Route path="/org" render={() => <OrgPage />} />}

      {isLoggedIn && (
        <Route path="/applications/:id" exact render={() => <CreateApplicationPage />} />
      )}
      {isLoggedIn && <Route path="/applications/:id/:type" exact render={() => <AddTrainer />} />}
      {isLoggedIn && (
        <Route path="/applications/:id/newList/:coverId" exact render={() => <NewList />} />
      )}
      <Route path="/roster" exact render={() => <RosterPage isLoggedIn={isLoggedIn} />} />
      <Route path="/matesListPublic" exact render={() => <MateItemsListPage />} />

      

      {isAdmin && <Route path="/directories" exact render={() => <DirectoriesPage />} />}
      {(isLoggedIn||isAdmin) && <Route path="/print/:id/:type" render={() => <PrintExample />} />}
      {!isAdmin && <Route path="/profile" exact render={() => <ProfilePageController />} />}
      {isLoggedIn && <Route path="/mates/:orgId" exact render={() => <MateItemPage />} />}
      {isLoggedIn && (
        <Route path="/mates/:orgId/:userId" exact render={() => <ProfilePageController />} />
      )}
      {isAdmin && (
        <Route path="/organisations/users/:userId" exact render={() => <ProfilePageController />} />
      )}
      {isLoggedIn && <Route path="/add-mate/:id" exact render={() => <AddMateItemPage />} />}
      {isLoggedIn && <Route path="/organisations" render={() => <OrganisationsPage />} />}
      {isLoggedIn && <Route path="/message" exact render={() => <MessagePage />} />}
      {<Route path="/report" exact render={() => <ReportPage />} />}
      {isLoggedIn && <Route path="/support" exact render={() => <SupportListPage />} />}
      {isLoggedIn && <Route path="/support/:id" exact render={() => <SupportItemPage />} />}
      <Route path="/instructions" exact render={() => <InstructionsPage />} />
      <Route path="/test" exact render={() => <TestPage />} />
      <Route path="/" exact>
        {isLoggedIn ? <Redirect to="/login" /> : <ApplicationsPage isLoggedIn={isLoggedIn} />}
      </Route>
      <Route path="/page404" render={() => <Page404 />} />
      <Redirect to="/page404" />
    </Switch>
  );
};
