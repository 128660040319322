import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCurrentOrganisation } from '../../../../redux/selectors/organisations';
import {
  selectCurrentOrganisationId,
  selectCurrentUserId,
  selectIsAdmin,
  selectIsHeadOrTrustedEmployee,
} from '../../../../redux/selectors/auth';
import Item from '../Item/Item';
import NewLoader from '../../../../components/NewLoader';

const Work = (props) => {
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const { applications } = useSelector((state) => state);
  const { userData } = useSelector((state) => state.auth);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const currentUserId = useSelector(selectCurrentUserId);
  const currentOrganisationId = useSelector(selectCurrentOrganisationId);
  const isAdmin = useSelector(selectIsAdmin);
  const isHeadOrTrustedEmployee = useSelector(selectIsHeadOrTrustedEmployee);

  const getList = useCallback(async (user) => {
    let data = [];
    setReady(false);
    if (isAdmin) {
      data = [
        { name: 'status', value: '1' },
        { name: 'status', value: '2' },
        { name: 'status', value: '3' },
        { name: 'status', value: '4' },
        { name: 'status', value: '6' },
        { name: 'status', value: '5' },
        { name: 'status', value: '7' },
        { name: 'status', value: '8' },
        { name: 'status', value: '9' },
        { name: 'status', value: '10' },
        { name: 'status', value: '11' },
        { name: 'status', value: '12' },
        { name: 'status', value: '13' },
        { name: 'status', value: '15' },
        { name: 'status', value: '14' },
        { name: 'status', value: '16'},
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'status', value: '20' },
        { name: 'status', value: '21' },
      ];
    } else if (!currentOrganisationId) {
      data = [{ name: 'status', value: '0' }];
    } else if (currentOrganisation && currentOrganisation.type === 0) {
      data = [
        { name: 'status', value: '0' },
        { name: 'status', value: '1' },
        { name: 'createdByOrganisationId', value: currentOrganisationId },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 1) {
      data = [
        { name: 'status', value: '2' },
        { name: 'regionId', value: currentOrganisation.regionId },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 2) {
      
      data = [
      { name: 'status', value: '5' },
      { name: 'federationId', value: '90d6f117-35ab-4680-b40f-89a1099edbea' },  //Неолімпійські
      { name: 'federationId', value: '52196b4b-1c3c-4c02-aa36-d7decb8e1553' },  //Літні види спорту
      { name: 'isTrainerReview', value:'0'}  
    ];
    if(props.subtab.curator){
      data.push({name: 'sportCategoryType', value: 1})
    }else if(props.subtab.department){
      data.push({name: 'sportCategoryType', value: 0})
    }
    else{
      data.push(
      { name: 'sportCategoryType', value: 0 });
      data.push(
      { name: 'sportCategoryType', value: 1 })
    }

    } else if (currentOrganisation && currentOrganisation.type === 3 && !isHeadOrTrustedEmployee) {
      if (props.subtab.departmentSection){
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '5' },
        ];
      } if (props.subtab.department){
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '8' },
        ];
      }
      else if (props.subtab.curator){
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '6' },
        ];
      }
      else{
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '4' },
        ];
      }
      
    } else if (currentOrganisation && currentOrganisation.type === 4 && !isHeadOrTrustedEmployee) {
      data = [
        { name: 'sportCategoryType', value: 1 },
        { name: 'status', value: '4' },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 4 && isHeadOrTrustedEmployee) {
      data = [
        { name: 'sportCategoryType', value: 1 },
        { name: 'status', value: '4' },
        { name: 'status', value: '9' },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 3 && isHeadOrTrustedEmployee) {
      if (props.subtab.departmentSection){
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '5' },
        ];
      } else if (props.subtab.department){
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '8' },
        ];
      }
      else if (props.subtab.curator){
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '6' },
        ];
      }
      else{
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '9' },
        ];
      }
      

    } else if (currentOrganisation && currentOrganisation.type === 5 && !isHeadOrTrustedEmployee) {
      if (props.subtab.curator){
        data = [
          { name: 'status', value: '11' },
        ];
      }
      else{
        data = [
          { name: 'status', value: '10' },
        ];
      }
    } else if (currentOrganisation && currentOrganisation.type === 5 && isHeadOrTrustedEmployee) {
      if (props.subtab.department){
        data = [
          { name: 'status', value: '10' },
        ];
      }else if (props.subtab.curator){
        data = [
          { name: 'status', value: '11' },
        ];
      }
      else{
        data = [
          { name: 'status', value: '13' },
          // { name: 'status', value: '10' },
        ];
      }
      
    } else if (currentOrganisation && currentOrganisation.type === 6 && !isHeadOrTrustedEmployee) {
      if (props.subtab.curator){
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '6' },
        ];}
        else{
          if(currentOrganisation.id =='90d6f117-35ab-4680-b40f-89a1099edbea' || currentOrganisation.id =='52196b4b-1c3c-4c02-aa36-d7decb8e1553'){
            data = [
              { name: 'status', value: '5' },
              { name: 'isTrainerReview', value:'1'},
              { name: 'federationId', value: currentOrganisation.id },
              { name: 'employeeId', value: currentUserId },
            ];
          }else{
            data = [
              { name: 'status', value: '5' },
              { name: 'federationId', value: currentOrganisation.id },
              { name: 'employeeId', value: currentUserId },
            ];
          }
         
        }
       if(!props.subtab.allMy){
        userData?.sports?.forEach(el=>{
          data.push({name:'sportId', value:el.id})
        })
       }
    } else if (currentOrganisation && currentOrganisation.type === 6 && isHeadOrTrustedEmployee) {
      if (props.subtab.departmentSection){
        data = [
          { name: 'federationId', value: currentOrganisation.id },
          { name: 'status', value: '5' },
        ];
      }
      else if (props.subtab.curator){
        data = [
          { name: 'sportCategoryType', value: 0 },
          { name: 'sportCategoryType', value: 1 },
          { name: 'status', value: '6' },
        ];
      }
      else{
        data = [
          { name: 'status', value: '8' },
          { name: 'federationId', value: currentOrganisation.id },
        ];
      }
    } else if (
      currentOrganisation &&
      (currentOrganisation.type === 7 || currentOrganisation.type === 8)
    ) {
      let sportCategoryType ='';
      if(currentOrganisation.type === 7){
        sportCategoryType=0;
      }else if (currentOrganisation.type === 8){
        sportCategoryType=1;
      }
      data = [
        { name: 'status', value: '6' },
        { name: 'status', value: '7' },
        { name: 'sportCategoryType', value: sportCategoryType },
       
      ];
      // if(!isHeadOrTrustedEmployee){
      //   data.push( { name: 'isTrainerReview', value:'0'},)
      // }
      if(!props.subtab.allMy){
        userData?.sports?.forEach(el=>{
          data.push({name:'sportId', value:el.id})
        })
       }
    } 
    else if (currentOrganisation && currentOrganisation.type === 9) {
      data = [
        { name: 'status', value: '11' },
        { name: 'federationId', value: currentOrganisation.id },
      ];
      if(!props.subtab.allMy){
        userData?.sports?.forEach(el=>{
          data.push({name:'sportId', value:el.id})
        })
       }
    }
    else if (currentOrganisation && currentOrganisation.type === 10) {
      data = [
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
      ];
    }
    if (props.search) {
      data.push({ name: 'all', value: props.search });
    }
    if (props.sort) {
      data.push({ sortName: props.sort.sortName, sortValue: props.sort.sortValue });
    }

    if (sessionStorage.work) {
      let work = JSON.parse(sessionStorage.work);

      props.setFilterForm(work);
      if (work.length > 0) {
        work.forEach((element) => {
          if (element && element.value.length > 0) {
            data.push(element);
          }
        });
      }

      props.setReload(false);
    }
    // if(props.filterForm && props.filterForm.length>0){
    //
    //   props.filterForm.forEach(el=>{
    //     data.push(el)
    //   })
    // }

    props.setData(data);
    await props.getApplications(data, props.currentPage).then((res) => {
      setReady(true);
    });
  }, [
    applications,
    userData,
    currentOrganisation,
    props.search,
    props.setData,
    props.sort,
    props.currentPage,
    props.subtab,
    ready
  ]);

  useEffect(() => {
    if (currentOrganisation && userData ) {
      void getList();
    }
  }, [
    currentOrganisation,
    userData,
    props.search,
    props.filterForm,
    props.reload,
    props.sort,
    props.currentPage,
    props.subtab,
  ]);

  return (
    <>
      {(ready &&
        applications &&
        applications.list &&
        applications.list.length > 0) ?
        applications.list.map((item) => {
          return (
            <Item
              selectTitle={props.selectTitle}
              selectId={props.selectId}
              selectForm={props.selectForm}
              item={item}
              type={props.type}
              selectHandler={props.selectHandler}
              key={item.id}
              history={history}
            />
          );
        })
      :
      <tr >
        <td colSpan="9">
          {ready && applications?.list?.length==0?<h2>Жодної заявки</h2>
          : <NewLoader/>}
        </td>
      </tr>
      }
      
    </>
  );
};

export default Work;
