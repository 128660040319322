import React, { useCallback, useEffect, useState } from 'react';
import Avatar from '../../../../components/Avatar/Avatar';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../../../redux/actions/file';
import {
  createDocument,
  deleteDocument,
  getDocumentsList,
} from '../../../../redux/actions/coverDocuments';
import { deleteCover, getCoverItem, updateCover } from '../../../../redux/actions/cover';
import { createCoverHistory } from '../../../../redux/actions/coverHistory';
import Form from '../../../DirectoriesPage/Form/Form';
import { selectCurrentOrganisation } from '../../../../redux/selectors/organisations';

const NewList = (props) => {
  if (
    document.getElementsByClassName('sidebar') &&
    document.getElementsByClassName('sidebar')[0] &&
    document.getElementsByClassName('sidebar')[0].hidden !== true
  ) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }
  const history = useHistory();
  const applicationId = useParams().id;
  const coverId = useParams().coverId;
  const { currentApplication } = useSelector((state) => state.applications);
  const { currentCover } = useSelector((state) => state.cover);
  const { coverDocuments } = useSelector((state) => state);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const [office, setOffice] = useState(false);
  const dispatch = useDispatch();
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const [comment, setComment] = useState({});
  const [form, setForm] = useState({ applicationId: applicationId, id: coverId });
  const [formActive, setFormActive] = useState(false);
  const sendLetter = (e) => {
    e.preventDefault();
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    let data = {
      id: coverDocuments[0].id,
      applicationId: applicationId,
      coverId: coverDocuments[0].letterId,
    };

    dispatch(deleteDocument(data)).then((res) => {
      getDoc();
    });
  };

  const officeAply = () => {
    let data = {
      applicationId: applicationId,
      status: 2,
      officeCode: comment.comment,
      id: coverId,
    };
    dispatch(createCoverHistory(data))
      .then((res) => {
        setFormActive(false);
        getItem();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const officeAplyClick = () => {
    setOffice(true);
    setFormActive(true);
  };

  const getDoc = useCallback(async () => {
    dispatch(getDocumentsList(form)).then((res) => {});
  }, []);

  const onDocumentUpload = useCallback(
    async (event) => {
      event.preventDefault();
      if (event.target.files[0]) {
        await dispatch(uploadFile(event.target.files[0]))
          .then((response) => {
            let obj = {
              form,
              document: { url: response, name: event.target.files[0].name, type: 0 },
            };
            dispatch(createDocument(obj)).then((res) => {
              getDoc();
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    [form],
  );

  const sendClick = (e) => {
    e.preventDefault();
    let data = { ...form };
    data.status = 1;
    dispatch(createCoverHistory(data)).then((res) => {
      history.push(`/applications/${applicationId}`);
    });
  };

  const backHandler = () => {
    history.push(`/applications/${applicationId}`);
  };
  const deleteClick = () => {
    dispatch(deleteCover(form)).then((res) => {
      history.push(`/applications/${applicationId}`);
    });
  };

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onBlurOut = (e) => {
    dispatch(updateCover(form)).then((res) => {});
  };
  const getItem = useCallback(() => {
    dispatch(getCoverItem(form)).then((res) => {
      let obj = { ...form };
      obj.message = res.message;
      obj.subject = res.subject;
      setForm(obj);
    });
  }, [form]);
  useEffect(() => {
    getDoc();
    getItem();
  }, []);
  return (
    <div className="main auth-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      {currentCover && currentCover.status === 0 && <h1 className="public-header">Новий лист</h1>}
      {currentCover && currentCover.status === 1 && (
        <h1 className="public-header">Лист очікує реєстрації</h1>
      )}
      {currentCover && currentCover.status === 2 && (
        <h1 className="public-header">№ {currentCover.officeCode}</h1>
      )}

      <div className="create-app btn-tab">
        <button className="icon-text-btn-secondary back" onClick={backHandler}>
          Назад
        </button>
        {currentCover &&
          currentCover.status === 0 &&
          currentOrganisation &&
          currentOrganisation.type === 0 && (
            <button className="icon-text-btn-secondary garbage" onClick={deleteClick}>
              Видалити
            </button>
          )}
        {currentApplication &&
          currentApplication.status == 3 &&
          currentCover &&
          currentCover.status === 1 &&
          currentOrganisation &&
          currentOrganisation.type === 2 && (
            <button className="icon-text-btn-secondary" onClick={officeAplyClick}>
              Зареєструвати лист
            </button>
          )}
      </div>
      <div className="public-table">
        <div
          style={{
            marginLeft: '20px',
            color: '#6D727C',
            fontSize: '16px',
            lineHeight: '20px',
          }}
        >
          <span>
            Лист від:{' '}
            {currentCover && currentCover.createdAt
              ? currentCover.createdAt
              : `${date} ${month} ${year}`}
          </span>
          <br />
          <span>{currentApplication && currentApplication.athleteCategoryTitle}</span>
          <br />
          <span>
            {currentApplication && currentApplication.athleteLastName}{' '}
            {currentApplication && currentApplication.athleteFirstName}{' '}
            {currentApplication && currentApplication.athleteSecondName}
          </span>
          <br />
          <span>{currentApplication && currentApplication.sportTitle}</span>
        </div>
        <div className="form-value">
          <form>
            <label className="labelText">
              <span style={{ marginBottom: '7px' }}>Тема *</span>
              <input
                style={{ 'min-width': '96%' }}
                disabled={currentCover && (currentCover.status === 1 || currentCover.status === 2)}
                onBlur={onBlurOut}
                onChange={changeHandler}
                type="text"
                value={form.subject}
                name="subject"
              />
            </label>
            <label className="labelText">
              <span style={{ marginBottom: '10px' }}>Супровідний текст *</span>
              <textarea
                disabled={currentCover && (currentCover.status === 1 || currentCover.status === 2)}
                rows="6"
                cols="100"
                style={{ resize: 'none', 'min-width': '93%' }}
                onChange={changeHandler}
                onBlur={onBlurOut}
                value={form.message}
                type="text"
                name="message"
              />
            </label>
            <label className="labelText" style={{ 'max-width': 'max-content' }}>
              <span>Додайте необхідні документи</span>
              {coverDocuments &&
                !coverDocuments[0] &&
                currentOrganisation &&
                currentOrganisation.type === 0 && (
                  <>
                    <label className="icon-text-btn-secondary dow" htmlFor="url">
                      Завантажити документ
                    </label>
                    <input
                      accept="doc,.docx,.xml,application/msword,application/vnd.openxmlprops.formats-officedocument.wordprocessingml.document,text/plain, application/pdf"
                      type="file"
                      id="url"
                      name="url"
                      className="documentInput"
                      onChange={onDocumentUpload}
                    />{' '}
                  </>
                )}
              {/* {form.document && form.document.url && <a href={form.document.url}  className='document-link'>{form.document.name}<i className='delete-icon doc-icon' onClick={deleteHandler}></i></a> }    */}
              {coverDocuments && coverDocuments[0] && (
                <a href={coverDocuments[0].url} className="document-link">
                  {coverDocuments[0].name}
                  {currentCover &&
                    currentCover.status === 0 &&
                    currentOrganisation &&
                    currentOrganisation.type === 0 && (
                      <i
                        className="delete-icon doc-icon"
                        id={coverDocuments && coverDocuments[0] && coverDocuments[0].id}
                        onClick={deleteHandler}
                      ></i>
                    )}
                </a>
              )}
            </label>
            {currentCover &&
              currentCover.status === 0 &&
              currentOrganisation &&
              currentOrganisation.type === 0 && (
                <button
                  style={{ width: '300px' }}
                  disabled={!(form.subject && form.message && coverDocuments[0])}
                  type="submit"
                  className="btn-primary text-btn-primary"
                  onClick={sendClick}
                >
                  Відправити
                </button>
              )}
          </form>
        </div>
      </div>
      {formActive && office && (
        <Form
          form={comment}
          saveAddChange={officeAply}
          setForm={setComment}
          office={office}
          setFormActive={setFormActive}
        />
      )}
    </div>
  );
};

export default NewList;
