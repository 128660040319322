import {
    SUPPORTGETLIST_SUCCESS,
    SUPPORTGETLIST_FAIL,
    SUPPORTDELETE_SUCCESS,
    SUPPORTDELETE_FAIL,
    SUPPORTGETITEM_SUCCESS,
    SUPPORTGETITEM_FAIL,
    SUPPORTREADED_SUCCESS,
    SUPPORTREADED_FAIL,
  } from './types';
  
  import SupportService from '../services/support.service';
  
  export const getSupportList = (data, pagination) => (dispatch) => {
    return SupportService.getSupportList(data, pagination).then(
      (response) => {
        if (response && response.items) {
          dispatch({
            type: SUPPORTGETLIST_SUCCESS,
            list: response.items,
            total: response.total,
            offset: response.offset,
          });
          return response.items;
        } else {
          return [];
        }
      },
      (error) => {
        dispatch({
          type: SUPPORTGETLIST_FAIL,
        });
        console.log(error);
        return Promise.reject();
      },
    );
  };




  export const createSupportRoom = (data) => (dispatch) => {
    return SupportService.createSupportRoom(data).then(
      (response) => {
        dispatch({
          type: SUPPORTGETITEM_SUCCESS,
          item: response,
        });
        return response;
      },
      (error) => {
        dispatch({
          type: SUPPORTGETITEM_FAIL,
        });
        console.log(error);
        return Promise.reject();
      },
    );
  };

  export const getSupportItem = (data) => (dispatch) => {
    return SupportService.getSupportItem(data).then(
      (response) => {
        dispatch({
          type: SUPPORTGETITEM_SUCCESS,
          item: response,
        });
        return response;
      },
      (error) => {
        dispatch({
          type: SUPPORTGETITEM_FAIL,
        });
        console.log(error);
        return Promise.reject();
      },
    );
  };

  export const addMessageSupportRoom = (data) => (dispatch) => {
    return SupportService.addMessageSupportRoom(data).then(
      (response) => {
        dispatch({
          type: SUPPORTGETITEM_SUCCESS,
          item: response,
        });
        return response;
      },
      (error) => {
        dispatch({
          type: SUPPORTGETITEM_FAIL,
        });
        console.log(error);
        return Promise.reject();
      },
    );
  };

  export const uploadSupport = (data) => (dispatch) => {
    return SupportService.uploadSupport(data).then(
      (response) => {
        return response;
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      },
    );
  };


  

  


  

