import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getNotificationListUnread } from '../../../redux/actions/notification';
import {
  selectCurrentOrganisationId,
  selectCurrentUserId,
  selectIsAdmin,
  selectIsHeadOrTrustedEmployee,
  selectIsRegionSport,
} from '../../../redux/selectors/auth';

export const SidebarAuth = () => {
  const dispatch = useDispatch();
  const [unRead, setUnRead] = useState(false);
  const [ready, setReady] = useState(false);
  const userId = useSelector(selectCurrentUserId);
  const organisationId = useSelector(selectCurrentOrganisationId);
  const isAdmin = useSelector(selectIsAdmin);
  const isRegionSport = useSelector(selectIsRegionSport);
  const isHeadOrTrustedEmployee = useSelector(selectIsHeadOrTrustedEmployee);



  const loadMessages = () => {
    setUnRead(false);
    dispatch(getNotificationListUnread({ id: userId })).then((res) => {
      setUnRead(res.result)
    });
  };

  useEffect(() => {
    if (!ready && userId) {


      loadMessages();
    }
  }, [ready]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userId) {
        loadMessages();
      }
    }, 20000); // 10000 milliseconds = 10 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [userId]);

  return (
    <>
      { organisationId && !isAdmin  && (
        <NavLink className="sidebar-nav" to={'/mates/' + organisationId} activeClassName="selected">
          <i className="sidebar-icon mates-icon"></i>
          Співробітники
        </NavLink>
      )}
      {isAdmin && (
        <NavLink className="sidebar-nav" to="/organisations" activeClassName="selected">
          <i className="sidebar-icon organisations-icon"></i>
          Організації та користувачі
        </NavLink>
      )}
      {isRegionSport && (
        <NavLink className="sidebar-nav" to="/organisations" activeClassName="selected">
          <i className="sidebar-icon organisations-icon"></i>
          Організації
        </NavLink>
      )}
      {isAdmin && (
        <NavLink className="sidebar-nav" to="/directories" activeClassName="selected">
          <i className="sidebar-icon directories-icon"></i>
          Довідники
        </NavLink>
      )}
      {userId && !isAdmin && (
        <NavLink className="sidebar-nav" to="/message" activeClassName="selected">
          {unRead ? (
            <i className="sidebar-icon message-icon unread"></i>
          ) : (
            <i className="sidebar-icon message-icon"></i>
          )}
          Повідомлення
        </NavLink>
      )}
      {
          <NavLink className="sidebar-nav" to="/support" activeClassName="selected">
            <i className="sidebar-icon directories-icon"></i>
            Підтримка
          </NavLink>
        }
      {userId && !isAdmin && (
        <NavLink className="sidebar-nav" to="/profile" activeClassName="selected">
          <i className="sidebar-icon profile-icon"></i>
          Профіль
        </NavLink>
      )}
    </>
  );
};
